@import
url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css);

html, body  {
    height:90vh;
}

body {
	font-family: system-ui, serif;
	display: grid;
	place-items: center;
	background-color: #101010;
	overflow: auto;
}

.socialbar   {
	text-align: center;
}

.socialbar li   {
    float: left;
    padding-left: 0.5em;
    padding-right: 5em;

}

.socialbar a {
	color: #8080BB;
    transition: 0.2;
	padding: 0em 1em;
	position: relative;
}

.socialbar a:hover    {
    top: -0.2vh;
}

@keyframes float    {
    from    {
        top: -0.5vw
    }
    to  {
        top: 0.5vw
    }
}

div h1    {
    animation-duration: 3s;
    animation-name: float;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
